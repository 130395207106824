<template>
  <ul v-if="!codeFree" class="methods-list space-y-3" data-cy="payment-methods">
    <Spinner v-if="isExtendPaymentMethodsLoading" class="w-6 h-6 mx-auto" />
    <template v-else>
      <li v-for="paymentMethod in internalPaymentMethods" :key="paymentMethod?.code">
        <RadioInput
          :id="`paymentMethod_${paymentMethod?.code}`"
          label=""
          name="paymentMethod"
          track-by="code"
          :value="paymentMethod"
          reversed
        >
          <div class="ml-2 flex items-center justify-between text-sm">
            <img
              v-if="paymentMethod"
              :src="resolveMethodIcon(paymentMethod) ? resolveMethodIcon(paymentMethod) : '/payment/credit-card.svg'"
              height="25"
              width="25"
              class="mr-2"
            />

            <p class="flex flex-col">
              <span class="text-black font-medium font-body">{{ paymentMethod?.title }}</span>
              <span v-if="paymentMethod?.description" class="text-gray-500">{{ paymentMethod.description }}</span>
            </p>
            <div
              v-if="paymentMethod?.code === 'robusta_mpgs_cc'"
              class="flex gap-2 lg:gap-5 items-center text-primary-500 mr-2 lg:mr-7"
            >
              <svg-icon-master_card class="w-7 h-4 object-contain lg:w-10 lg:h-10" />
              <svg-icon-visa class="w-9 h-3 object-contain lg:w-12 lg:h-12" />
            </div>
          </div>

          <template #after>
            <PaymentStrategy
              v-if="
                selectedMappedMethod &&
                paymentMethod?.code === selectedMappedMethod.code &&
                selectedMappedMethod.component
              "
              :payment-component="selectedMappedMethod.component"
              class="mt-5"
            />
          </template>
        </RadioInput>
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import type { AvailablePaymentMethod } from '@robustastudio/e-commerce/common';

function resolveMethodIcon(paymentMethod: Omit<AvailablePaymentMethod, 'is_deferred'>): string {
  const mapIcons: Record<string, string> = {
    cashondelivery: '/payment/cash-on-delivery.svg',
    robusta_accept_cc: '/payment/credit-card.svg',
    cardondelivery: '/payment/credit-on-delivery.svg',
  };

  return mapIcons[paymentMethod.code];
}

const props = defineProps({
  paymentMethods: {
    type: Array as PropType<Array<Omit<AvailablePaymentMethod, 'is_deferred'> | null | undefined>>,
    default: () => [],
  },
  codeFree: {
    type: Boolean,
    default: () => false,
  },
});

const { isLoggedIn } = useAuth();
const {
  executeExtendCustomerPaymentMethods,
  executeExtendGuestPaymentMethods,
  isExtendPaymentMethodsLoading,
  extendedPaymentMethods,
} = useExtendPaymentMethods();

const { value: selectedPaymentMethod } = useField<AvailablePaymentMethod>('paymentMethod');
const internalPaymentMethods = ref<Array<Omit<InternalPaymentMethod, 'is_deferred'>>>([]); //  will hold the attributes for payment methods (core & extended)

const selectedMappedMethod = computed(() => {
  return (
    selectedPaymentMethod.value &&
    props.paymentMethods
      .filter((method: any): method is AvailablePaymentMethod => method !== null)
      .map(method => mapPaymentOption(method))
      .find(method => method.code === selectedPaymentMethod.value?.code)
  );
});

/**
 * watch for any payment methods update
 * Then fetch the extended payment methods again to match the new payment methods
 * The internalPaymentMethods will hold the all payment methods information (core & extended)
 */
watch(
  () => props.paymentMethods,
  async newValue => {
    if (!newValue.length) return;

    if (isLoggedIn.value) {
      await executeExtendCustomerPaymentMethods();
    } else {
      await executeExtendGuestPaymentMethods();
    }

    internalPaymentMethods.value = newValue.map((method, index) => ({
      ...method,
      code: method?.code || '',
      title: method?.title || '',
      description: extendedPaymentMethods.value[index]?.description || '',
    }));
  },
  { immediate: true },
);
</script>
<style lang="postcss" scoped>
.methods-list li:not(:last-child) {
  @apply flex flex-col;
  &::after {
    @apply bg-primary-2-02 my-7;
    content: '';
    width: 100%;
    height: 1px;
  }
}
</style>
