<template>
  <component :is="componentToRender" v-if="paymentComponent" v-bind="$attrs">
    <slot />
  </component>
</template>
<script setup lang="ts">
const props = defineProps({
  paymentComponent: {
    type: String,
    default: '',
  },
});
const componentToRender = defineAsyncComponent(() => import(`./With/${props.paymentComponent}.vue`));
</script>
