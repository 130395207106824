<template>
  <div class="relative flex flex-col">
    <h3 class="flex mb-5">
      <svg-icon-coupon width="25" height="25" class="mr-2 text-primary-1-100" />
      {{ $t('haveAPromoCode') }}
      <svg-icon-redeemed v-if="appliedCoupon.length" width="22" height="22" class="ml-2" />
    </h3>
    <template v-if="!appliedCoupon.length">
      <button
        :aria-label="$t('haveAPromoCode')"
        :aria-pressed="Boolean(toggleCoupons)"
        type="button"
        @click="toggleCoupons = !toggleCoupons"
      >
        <span
          class="checkbox p-1 ml-auto flex justify-center items-center absolute top-0 right-0 rounded-md"
          :class="{
            active: toggleCoupons || appliedCoupon.length,
          }"
        >
          <svg-icon-check :class="{ invisible: !toggleCoupons, 'text-white': toggleCoupons }" class="stroke-current" />
        </span>
      </button>
    </template>
    <form @submit="onSubmit">
      <Coupons
        v-if="toggleCoupons || appliedCoupon.length"
        :applied-coupons="appliedCoupon"
        :loading="loading"
        class="block"
        @remove="() => removeCouponOnCart()"
      >
        <template #cancel>
          <button type="button" class="text-red-700 font-body underline ml-5" @click="toggleCoupons = !toggleCoupons">
            {{ $t('cancel') }}
          </button>
        </template>
      </Coupons>
    </form>
    <ModalDialog v-model="showPromocodeConflict">
      <h3 class="font-body font-semibold text-xl md:self-start text-primary-700">{{ $t('promocodeConflict') }}</h3>
      <p class="text-center md:text-left mt-4">
        {{ $t('promocodeConflictWarning', { value: possibleCartPriceRuleDiscount }) }}
      </p>

      <div class="flex flex-col md:flex-row items-center justify-center gap-4 w-full mt-7">
        <AppButton
          class="uppercase justify-center whitespace-nowrap w-full max-w-xs"
          inverted
          @click="showPromocodeConflict = false"
        >
          {{ $t('confirmPromocode') }}
        </AppButton>
        <AppButton
          class="uppercase justify-center w-full max-w-xs"
          @click="
            removeCouponOnCart();
            showPromocodeConflict = false;
          "
        >
          {{ $t('cancel') }}
        </AppButton>
      </div>
    </ModalDialog>
  </div>
</template>
<script setup lang="ts">
import { object, string } from 'yup';

defineComponent({
  name: 'CheckoutCoupons',
});

const { appliedCoupon: cartCoupons, subtotal } = useCartAttributes();
const { isFetching: isApplyingPromoCode, applyCoupon } = useApplyPromocode();
const { isFetching: isRemovingPromoCode, removeCode } = useRemovePromocode();
const { cartPriceRuleDiscounts } = useExtendedCartAttributes();
const showPromocodeConflict = ref(false);
// Possible cart price rule discount from Buy More Save More feature
// If not conflicting with applied promocode
const possibleCartPriceRuleDiscount = ref(0);

const { error, success } = useAlerts();
const { t } = useI18n({ useScope: 'global' });
const { t: $t } = useI18n({
  useScope: 'local',
});

const { handleSubmit, setErrors, resetForm } = useForm({
  validationSchema: toTypedSchema(
    object({
      code: string().required(),
    }),
  ),
});

const toggleCoupons = ref(false);

const emit = defineEmits<{
  (event: 'isOpen', isOpen: boolean): void;
}>();
const onSubmit = handleSubmit(async ({ code }) => {
  try {
    await applyCoupon(code);
    success(t('cartSuccess').toString(), t('couponAdded').toString());
    toggleCoupons.value = false;
    resetForm();

    // Check if 1. There are cart price rule discounts
    // 2. If no tier is applied
    // 3. If the subtotal is greater than the first tier minimum subtotal
    showPromocodeConflict.value = Boolean(
      cartPriceRuleDiscounts.value.tiers.length &&
        cartPriceRuleDiscounts.value.eligibleTierIdx < 0 &&
        subtotal.value > cartPriceRuleDiscounts.value.tiers[0].subtotalValue,
    );

    // If there is a conflict get possible discount from cart price rules
    if (showPromocodeConflict.value) {
      const maxTierIdx = cartPriceRuleDiscounts.value.tiers.findIndex(tier => tier.subtotalValue > subtotal.value);
      possibleCartPriceRuleDiscount.value =
        maxTierIdx > 0
          ? cartPriceRuleDiscounts.value.tiers[maxTierIdx - 1].discountValue
          : cartPriceRuleDiscounts.value.tiers[0].discountValue;
    }
  } catch (e) {
    error(t('cartError').toString(), t((e as Error).message).toString());

    setErrors({ code: t((e as Error).message).toString() });
  }
});

async function removeCouponOnCart() {
  try {
    await removeCode();
    success(t('cartSuccess').toString(), t('couponRemoved').toString());
  } catch (e) {
    error(t('cartError').toString(), t((e as Error).message).toString());
  }
}

watch(
  toggleCoupons,
  newValue => {
    emit('isOpen', newValue);
  },
  {
    immediate: true,
  },
);

const appliedCoupon = computed(() => (cartCoupons.value ? [cartCoupons.value] : []));

const loading = computed(() => isApplyingPromoCode.value || isRemovingPromoCode.value);
</script>
<i18n>
{
  "en": {
    "haveAPromoCode": "Do you have a promocode?",
    "cancel": "Cancel",
    "promocodeConflictWarning": "Adding the following promocode will cancel the existing “Buy More Save More” {value}% Discount. Are you sure you want to proceed?",
    "confirmPromocode": "Yes, Add Code",
    "promocodeConflict": "Promocode Conflict",
    "cartError":"Something went wrong!",
  },
  "ar": {
    "haveAPromoCode": "لديك برومو كود ؟",
    "cancel": "الغاء",
    "promocodeConflictWarning": "اضافة الكوبون التالي سيؤدي الى الغاء الخصم الحالي {value}% خصم. هل انت متأكد من الاستمرار؟",
    "confirmPromocode": "نعم، اضف الكود",
    "promocodeConflict": "تعارض الكوبونات",
    "cartError":"حدث خطأ ما",
  }
}
</i18n>

<style lang="postcss" scoped>
.checkbox {
  @apply h-6 w-6  border border-primary-1-100 mr-2;

  &.active {
    @apply bg-primary-1-100 border-white;
  }
}
</style>
