import * as yup from 'yup';

export function useCheckoutPaymentValidationSchema() {
  const validationSchema = computed(() => {
    return yup.object({
      paymentMethod: yup.object().required(),
      shippingMethod: yup.object().required(),
    });
  });

  return {
    validationSchema,
  };
}
